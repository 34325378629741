import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    getters: {
        adminMenu: (state) => state.adminMenu,
        schoolMenu: state => state.schoolMenu,
        teacherMenu: state => state.teacherMenu,
        studentMenu: (state) => state.studentMenu,
    },
    state: {
        adminMenu: [],
        schoolMenu: [],
        teacherMenu: [],
        studentMenu: [],
    },
    mutations: {
        SET_ADMIN_ROUTES: (state, routes) => {
            state.adminMenu = routes;
        },
        SET_STUDENT_ROUTES: (state, routes) => {
            state.studentMenu = routes;
        },
        SET_SCHOOL_ROUTES: (state, routes) => {
            state.schoolMenu = routes
        },
        SET_TEACHER_ROUTES: (state, routes) => {
            state.teacherMenu = routes
        },
    },
    actions: {
        generateAdminRoutes({commit}, adminRoutes) {
            commit("SET_ADMIN_ROUTES", adminRoutes);
        },
        generateStudentRoutes({commit}, studentRoutes) {
            commit("SET_STUDENT_ROUTES", studentRoutes);
        },
        generateSchoolRoutes({commit}, schoolRoutes) {
            commit('SET_SCHOOL_ROUTES', schoolRoutes)
        },
        generateTeacherRoutes({commit}, teacherRoutes) {
            commit('SET_TEACHER_ROUTES', teacherRoutes)
        },
    },
    modules: {},
});