<template>
    <div class="student-container">
        <div class="left-one-tab">
            <div class="head-title">
                <i class="iconfont">&#xe656;</i>
                <h2 class="title">电商直播实训系统</h2>
            </div>
            <div class="user-info">
                <div class="avatar">
                    <img class="avatar-img" :src="avatar" :alt="name" :title="name" />
                </div>
                <div class="username">
                    <div class="text text-overflow">{{ name }}</div>
                    <i class="iconfont" @click="logout">&#xe88a;</i>
                </div>
            </div>
            <div class="one-menu">
                <el-scrollbar style="height: 100%" v-if="studentMenu.length > 0">
                    <router-link
                        v-for="oneLevel in studentMenu"
                        :to="oneLevel.path"
                        class="one-level-item"
                        :class="oneLevel | oneLevelClass($route)"
                        :key="oneLevel.name"
                    >
                        <i class="iconfont" v-html="oneLevel.meta.icon"></i>
                        <span class="one-level-title">{{ oneLevel.meta.title }}</span>
                    </router-link>
                </el-scrollbar>
            </div>
        </div>
        <div class="right-box">
            <div class="embed-page" v-if="showEmbed">
                <router-view name="embed"></router-view>
            </div>
            <div class="two-tab-box">
                <div class="head-two-tab" v-if="showTwoTabBar">
                    <div class="two-tab-list" v-if="twoLevelMenu.length > 1 && showTwoTab">
                        <router-link
                            v-for="twoLevel in twoLevelMenu"
                            :to="twoLevel.path"
                            class="two-tab-item"
                            :class="twoLevel | twoLevelClass($route)"
                            :key="twoLevel.name"
                        >
                            <div class="two-text">
                                <span class="text-left"></span>
                                <span class="text-main">{{ twoLevel.meta.title }}</span>
                            </div>
                            <div class="wider-box"></div>
                        </router-link>
                    </div>
                </div>
                <div class="content">
                    <el-scrollbar style="height: 100%">
                        <router-view />
                    </el-scrollbar>
                </div>
            </div>
            <count-down class="count-down"></count-down>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import CountDown from "components/CountDown";
import { Logout } from "@/utils/apis";

export default {
    name: "StudentLayout",
    data() {
        return {
            role: "",
            name: localStorage.getItem('nickname') || null,
            avatar: require("../../assets/image/blank_headPic.png"),
        };
    },
    computed: {
        ...mapState({
            studentMenu: (state) => state.studentMenu[0].children,
        }),
        activeOneMenu() {
            const { meta } = this.$route;
            return this.studentMenu.find(
                (item) => (meta.parentName && meta.parentName[0] == item.name) || item.name == this.$route.name
            );
        },
        twoLevelMenu() {
            return this.activeOneMenu.children ? this.activeOneMenu.children.filter((item) => !item.meta.hidden) : [];
        },
        showTwoTab() {
            return this.$route.meta.level && this.$route.meta.level >= 2 ? false : true;
        },
        showEmbed() {
            return this.$route.meta.embed === undefined && this.activeOneMenu.meta.embed;
        },
        showTwoTabBar() {
            return this.$route.meta.name != "LiveRoomManage" && !this.$route.meta.parentName.includes("LiveRoomManage");
        },
    },
    components: {
        CountDown,
    },
    filters: {
        oneLevelClass(oneLevel, route) {
            return {
                "one-level-active":
                    oneLevel.name === route.name || (route.meta.parentName && route.meta.parentName.includes(oneLevel.name)),
            };
        },
        twoLevelClass(twoLevel, route) {
            return {
                "tow-level-active": twoLevel.name === route.name || route.meta.parentName.includes(twoLevel.name),
                wider: twoLevel.meta.wider,
            };
        },
    },
    methods: {
        async logout() {
            const res = await Logout();
            localStorage.clear();
            this.$message.success(res.msg);
            this.$router.push("/");
        },
    },
    created() {
        this.role = localStorage.getItem("role");
        let avatar = localStorage.getItem("studentAvatar");
        this.avatar = avatar ? avatar : require("../../assets/image/blank_headPic.png")
    },
};
</script>

<style scoped lang="scss">
$blurColor: #a4a4af;
* {
    padding: 0;
    margin: 0;
}

.student-container {
    box-sizing: border-box;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: #0c0e3f;
    overflow: hidden;
    .left-one-tab {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        min-width: 230px;
        max-width: 230px;
        height: 100%;
        padding-bottom: 15px;
        .head-title {
            display: flex;
            align-items: center;
            margin-top: 10px;
            color: #edf1fd;
            .iconfont {
                margin: 0 10px;
                color: #4b45ff;
                font-size: 30px;
            }
            .title {
                font-weight: 400;
                font-size: 20px;
            }
        }
        .user-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 90px 0 105px 0;
            .avatar {
                width: 84px;
                height: 84px;
                border-radius: 50%;
                overflow: hidden;
                .avatar-img {
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                }
            }
            .username {
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-top: 18px;
                padding: 0 15px;
                font-size: 18px;
                color: #fff;
                .iconfont {
                    margin-left: 9px;
                    font-size: 25px;
                    cursor: pointer;
                }
            }
        }
        .one-menu {
            flex: 1;
            .one-level-item {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                width: 216px;
                height: 60px;
                padding-left: 40px;
                margin-top: 20px;
                color: $blurColor;
                font-size: 18px;
                border-radius: 0 20px 20px 0;
                overflow: hidden;
                .iconfont {
                    margin-right: 19px;
                    color: #4742ef;
                    font-size: 26px;
                }
            }
            .one-level-item:first-child {
                margin-top: 0;
            }
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
    }
    .right-box {
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex: 1;
        background-color: #070932;
        overflow: hidden;
        .embed-page {
            box-sizing: border-box;
            width: 619px;
        }
        .two-tab-box {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            flex: 1;
            width: 100%;
            .head-two-tab {
                box-sizing: border-box;
                display: flex;
                width: 100%;
                height: 70px;
                background-color: #0c0e3f;
                overflow: hidden;
                .two-tab-list {
                    box-sizing: border-box;
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    height: 70px;
                    background-color: #0c0e3f;
                    .two-tab-item {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 144px;
                        height: 50px;
                        color: $blurColor;
                        font-size: 18px;
                        border-radius: 20px 20px 0 0;
                    }
                }
            }
            .content {
                box-sizing: border-box;
                flex: 1;
                overflow: hidden;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                    .el-scrollbar__view {
                        height: 100%;
                    }
                }
            }
        }
        .count-down {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}
.one-level-active {
    color: #fff !important;
    background-color: #262963;
}
.tow-level-active {
    position: relative;
    color: #fff !important;
    background-color: #070932;
    .two-text {
        z-index: 99;
    }
    .wider-box {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 184px;
        height: 20px;
        background-color: #070932;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 20px;
            height: 20px;
            background-color: #0c0e3f;
            z-index: 9;
            border-radius: 0 0 20px 0;
        }

        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            width: 20px;
            height: 20px;
            background-color: #0c0e3f;
            z-index: 9;
            border-radius: 0 0 0 20px;
        }
    }
}
.wider {
    width: 160px;
}
</style>
