import axios from 'axios';
import router from "../router";
import {Message} from 'element-ui'

export const instance = axios.create({
    // baseURL: 'http://app.e-class.me/',
    timeout: 30000,
    headers: {
        'Cache-Control': 'no-cache',
    },
});
instance.defaults.headers.post['Content-Type'] = 'application/json';

const rolesToken = {
    1: 'adminToken',
    2: 'schoolToken',
    3: 'trainToken',
    4: 'teacherToken',
    5: 'studentToken',
}

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(rolesToken[router.currentRoute.meta.role])
        if (token) {
            config.headers.Authorization = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);
// 响应拦截
instance.interceptors.response.use(
    (response) => {
        if (response.data.code === 200) {
            return response.data;
        } else if (response.data.code === 204) {
            Message.warning(response.data.msg);
            localStorage.clear();
            if (router.currentRoute.fullPath !== '/') {
                router.push('/');
            }
        } else {
            Message.warning(response.data.msg)
            return Promise.reject(response.data);
        }
    },
    (error) => {
        Message.error(String(error))
        return Promise.reject(error);
    },
);
export default {
    get(url, config = {}) {
        return instance.get(url, config);
    },
    post(url, data = {}, config = {}) {
        return instance.post(url, data, config);
    },
    delete(url, config = {}) {
        return instance.delete(url, config);
    },
    patch(url, data, config = {}) {
        return instance.patch(url, data, config);
    },
    put(url, data, config = {}) {
        return instance.put(url, data, config);
    },
};
