import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "../theme/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./permission";
import "./assets/style/iconfont.css";
import "./assets/style/index.scss";
import tinymce from "tinymce";
import VueTinymce from "@packy-tang/vue-tinymce";
import "./common/font.css";
import * as echarts from "echarts";

Vue.prototype.$bus = new Vue();
Vue.prototype.$tinymce = tinymce;

Vue.prototype.$echarts = echarts;

Vue.use(ElementUI);
Vue.use(VueTinymce);
Vue.config.productionTip = false;
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
