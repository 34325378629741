<template>
    <div class="count-down">
        <div class="iconfont icon">&#xe619;</div>
        <div class="hint">{{timeText}}</div>
        <div class="time">{{countDownList}}</div>
    </div>
</template>

<script>
export default {
    name: "StudentCountDown",
    data() {
        return {
            timeText: '',
            countDownList: '00 :00 : 00 : 00',
            timeInterval: null,
        };
    },
    computed: {},
    components: {},
    methods: {
        countDown() {
            let examStartTime = localStorage.getItem('start_time')
            let examEndTime = localStorage.getItem('end_time')
            this.timeInterval = setInterval(() => {
                // 获取当前时间
                let newTime = Math.round(new Date() / 1000)
                //获取开始时间，结束时间
                let endTime = new Date(examEndTime).getTime() / 1000
                let startTime = new Date(examStartTime).getTime() / 1000
                let obj = null
                //开始比赛
                if (newTime - startTime > 0) {
                    this.$emit('examStatus', true)
                    this.timeText = '距离实训结束还有：';
                    // 如果活动未结束，对时间进行处理
                    if (endTime - newTime > 0) {
                        let time = endTime - newTime;
                        // 获取天、时、分、秒
                        let day = parseInt(time / (60 * 60 * 24));
                        let hou = parseInt(time % (60 * 60 * 24) / 3600);
                        let min = parseInt(time % (60 * 60 * 24) % 3600 / 60);
                        let sec = parseInt(time % (60 * 60 * 24) % 3600 % 60);
                        obj = {
                            day: this.timeFormat(day),
                            hou: this.timeFormat(hou),
                            min: this.timeFormat(min),
                            sec: this.timeFormat(sec)
                        };
                    } else { // 活动已结束，全部设置为'00'
                        obj = {
                            day: '00',
                            hou: '00',
                            min: '00',
                            sec: '00'
                        };
                        clearInterval(this.timeInterval);
                        this.$message({
                            type: 'error',
                            message: '实训时间到！停止作答！',
                            duration: '1000'
                        });
                        this.$router.push('/');
                        this.$emit('examStatus', false)
                    }
                    this.countDownList = obj.day + ' : ' + obj.hou + ' : ' + obj.min + ' : ' + obj.sec + '';

                    //比赛或者训练结束时，结束推广
                    if (this.countDownList === '00 : 00 : 00 : 00') {

                    }
                } else { //还未开始比赛
                    this.$emit('examStatus', false)
                    this.timeText = '距离实训开始还有：';
                    let time = startTime - newTime;
                    //当时间差为0时，刷新页面
                    if (time === 0){
                        window.location.reload();
                    }
                    // 获取天、时、分、秒
                    let day = parseInt(time / (60 * 60 * 24));
                    let hou = parseInt(time % (60 * 60 * 24) / 3600);
                    let min = parseInt(time % (60 * 60 * 24) % 3600 / 60);
                    let sec = parseInt(time % (60 * 60 * 24) % 3600 % 60);
                    obj = {
                        day: this.timeFormat(day),
                        hou: this.timeFormat(hou),
                        min: this.timeFormat(min),
                        sec: this.timeFormat(sec)
                    };
                    this.countDownList = obj.day + ' : ' + obj.hou + ' : ' + obj.min + ' : ' + obj.sec + '';
                }
            }, 1000);
        },
        //倒计时
        timeFormat(param) {
            return param < 10 ? '0' + param : param;
        },
    },
    mounted() {
        this.countDown()
    },
};
</script>

<style scoped lang="scss">
.count-down {
    display: flex;
    height: 43px;
    padding: 0 8px 0 21px;
    font-size: 16px;
    color: #ff3e6c;
    line-height: 43px;
    background-color: #262963;
    border-radius: 0 0 0 20px;
    .icon {
        font-size: 24px;
        margin-right: 11px;
    }
    .hint {
        color: #fff;
    }
    .time {
        text-indent: 7px;
    }
}
</style>
